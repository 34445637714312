.background {
  background-color: #fff7e3; /* Your base background color */
  background-image: radial-gradient(circle, #f9d8a7 10%, transparent 11%),
    radial-gradient(circle, #f9d8a7 10%, transparent 11%);
  background-size: 50px 50px;
  background-position:
    0 0,
    25px 25px; /* Offsets the dots to make a pattern */
}

.secondary-header-component {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.secondary-header-component h1 {
  position: relative;
  color: #5c3b1b;
  font-size: 3.5rem;
  font-family: Lobster, sans-serif;
}

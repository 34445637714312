.contact-us-component {
  padding: 2vh;
  background-color: #6e451d;
  color: #fff7e3;
}

.contact-us-component h2 {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
}

.contact-us-heading {
  margin-bottom: 1.5vh;
}

.contact-us-content {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
}

.contact-us-child-component {
  margin-bottom: 1vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-us-child-component-content {
  padding-bottom: 0.5vh;
}

.sub-heading {
  font-size: 1.15rem;
  font-weight: 500;
}

.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}

.social-media a {
  display: flex;
  padding-left: 0.5vh;
  padding-right: 0.5vh;
}

.contact-us-child-component a {
  color: #fff7e3;
}

.image-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;

  /* background-image: url("../../../../public/images/image.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 40vh;
}

.forms-container {
  display: grid;
}

.submit-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2%;
}

.submit-button-container button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffc3e1; /* Background color */
  box-shadow: 5px 7px 1px rgb(0 0 0 / 45%);
  border: 0.5px solid;
  color: black;
  text-decoration: none;
  font-family: inherit;
  font-size: 1rem;
  border-radius: 10px;
  height: 100%;
  width: 40%;
  font-weight: 550;
  margin-top: 10px;
  margin-bottom: 10px;
}

.submit-disabled {
  opacity: 0.5;
}

.secondary-header-component-owner-view {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff7e3;
}

.secondary-header-component-owner-view h1 {
  position: relative;
  color: #5c3b1b;
  font-size: 3.5rem;
  font-family: Lobster, sans-serif;
  margin-top: 5%;
  margin-bottom: 5%;
}

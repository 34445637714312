.navbar-component {
  background-color: #8ed0d3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  height: 7vh;
}

.navbar-logo {
  padding: 0 0.5%;
  font-family: Lobster, sans-serif;
  color: #5c3b1b;
}

.navbar-logo a {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.navbar-logo-text {
  color: #5c3b1b;
  text-decoration: none;
  margin: 0;
}

.navbar-logo img {
  display: inline-block; /* This allows margin: 0 auto; to work */
  height: 6vh;

  /* width: 100%; */
}

/* Styles for dropdown */
.navbar-menu {
  position: relative;
}

.dropdown-button {
  background-color: transparent;

  /* color: white; */

  /* padding: 10px; */
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 20%;
  background-color: #5c3b1b;
  border: 1px solid #ccc;

  /* box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); */
  z-index: 1;
  width: 150px;
  border-radius: 10%;
}

.dropdown-menu li {
  list-style: none;
  border-radius: 10%;
}

.navbar-tabs a {
  text-decoration: none;
  color: black;
}

.dropdown-menu li a {
  display: block;
  padding: 5px;
  text-decoration: none;
  color: #fff7e3;
  font-size: 16px;
}

.navbar-tabs-dropdown {
  margin: 0;
  padding: 1%;
}

.navbar-tabs {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
}

.navbar-tabs li {
  list-style: none;
  height: 100%;
}

.navbar-tabs li a {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;

  /* height: 7vh; */
  height: 100%;
  width: 100px;
  padding-right: 5px;
  padding-left: 5px;
  text-decoration: none;
  border-left: 2px solid black;
}

@media (width >= 1200px) {
  /* stylelint-disable */
  .smallScreen {
    display: none;
  }
  .largeScreen {
    height: 100%;
    position: relative;
  }
  /* stylelint-enable */
}

@media (width < 1200px) {
  /* stylelint-disable */
  .largeScreen {
    display: none;
  }
  /* stylelint-enable */
}

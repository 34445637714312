.gallery-component {
  padding: 3vh;
  padding-top: 0;
}

.gallery-component h2 {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.gallery-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 35vh;

  /* padding-bottom: 5%; */
}

.gallery-content img {
  max-width: 80%;
  max-height: 100%;
  object-fit: contain;
}

.gallery-content button {
  background-color: transparent;
  border: none;
  margin: 10px;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.password-container {
  display: flex;
  gap: 1%;
  justify-content: center;
  align-items: center;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
  width: 100%;
}

.login-container > button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffc3e1; /* Background color */
  box-shadow: 5px 5px 1px rgb(0 0 0 / 45%);
  border: 0.5px solid;
  color: black;
  text-decoration: none;
  font-family: inherit;
  font-size: 1rem;
  border-radius: 10px;
  height: 4vh;
  width: 10%;
  font-weight: 550;
  margin-bottom: 1.5%;
}

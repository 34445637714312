.free-toppings-form-options {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 2%;
  width: 100%;
}

.free-toppings-option {
  margin: 5px;
  width: 30%;
  height: 50px;
  position: relative;
}

.free-toppings-label {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  inset: 0;
  padding: 5px;
  border: 2px solid #ccc;
  cursor: pointer;
  z-index: 90;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 45%);
  border-radius: 10px;
}

.free-toppings-option input {
  display: block;
  position: absolute;
  inset: 0;
  margin: 0;
  padding: 5px;
}

.free-toppings-option input[type="checkbox"] {
  opacity: 0.01;
  z-index: 100;
}

.checked + .free-toppings-label,
.free-toppings-option input[type="checkbox"]:checked + .free-toppings-label {
  border: 3px solid #ffc3e1;
}

.about-us-component {
  /* background-color: white; */
  padding: 3vh;
}

.about-us-component h2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
}

.about-us-component div {
  text-align: center;
}

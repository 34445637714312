.contact-us-page-component {
  margin: 2vh;
}

.loading-screen {
  position: absolute;
  z-index: 1000;
  color: black;
}

.contact-us-page-component h2,
h3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
}

.contact-us-page-component h3 {
  margin: 2vh;
}

.contact-us-page-component h4 {
  text-align: center;
}

.contact-us-page-header-content {
  text-align: center;
}

.contact-us-child-component {
  width: 100%;
}

.catering-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.catering-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #5c3b1b;
  width: 70%;
  margin-top: 2vh;
  padding-bottom: 2vh;
  color: #fff7e3;
  border-radius: 10px;
}

.catering-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.catering-form-field {
  display: flex;
  width: 100%;
  margin-bottom: 1vh;
  justify-content: center;
  align-items: center;
}

.catering-form-field-key {
  flex: 40%;
}

.catering-form-field-value {
  flex: 60%;
}

.catering-submit-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.catering-submit-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffc3e1; /* Background color */
  box-shadow: 5px 5px 1px rgb(0 0 0 / 45%);
  border: 0.5px solid;
  color: black;
  text-decoration: none;
  font-family: inherit;
  font-size: 1rem;
  border-radius: 10px;
  height: 3.5vh;
  font-weight: 550;
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 30%;
}

@media (width <= 1200px) {
  .catering-form-container {
    width: 100%;
  }

  input#first3,
  input#next3,
  input#last4 {
    width: 20%;
  }

  input#date {
    width: 75%;
  }

  input#number {
    width: 30%;
  }
}

@media (width > 1200px) {
  .catering-form-container {
    width: 100%;
  }

  input#first3,
  input#next3,
  input#last4 {
    width: 12%;
  }

  input#date {
    width: 35%;
  }

  input#number {
    width: 15%;
  }
}

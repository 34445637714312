.empty-cart-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 10px;
}

.cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order-table {
  padding: 5px;
  width: 100%;
}

thead {
  border-bottom: 0.5px solid;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th.order-column {
  width: 80%;
  text-align: left;
}

th.total-column {
  width: 10%;
  text-align: center;
}

td.total-column {
  text-align: center;
  vertical-align: top;
}

/* .no-scroll {
  overflow: hidden !important;
} */

.place-order-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2%;
  width: 100%;
}

.place-order-container button,
.place-order-container a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffc3e1; /* Background color */
  box-shadow: 5px 5px 1px rgb(0 0 0 / 45%);
  border: 0.5px solid;
  color: black;
  text-decoration: none;
  font-family: inherit;
  font-size: 1rem;
  border-radius: 10px;
  height: 3vh;
  width: 40%;
  font-weight: 550;
  margin-top: 10px;
  margin-bottom: 10px;
}

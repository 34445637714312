.view-menu-component {
  margin: 2vh;
}

.view-menu-component h2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
}

.view-menu-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.promotional-deals-component-menu {
  display: flex;
  flex-direction: column;
  margin: 2vh;
  margin-bottom: 3vh;
  margin-top: 0;
  height: 100%;
}

.flavor-of-the-week-deals-component-menu {
  display: flex;
  flex-direction: column;
  margin: 2vh;
  margin-bottom: 3vh;
  margin-top: 0;
  height: 100%;
}

.creamy-scoops-component-menu {
  display: flex;
  flex-direction: column;
  margin: 2vh;
  height: 100%;
}

.promotional-deals-component-menu h2,
.flavor-of-the-week-deals-component-menu h2,
.creamy-scoops-component-menu h2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
}

.promotional-deals-content-menu {
  display: flex;
  row-gap: 2vh;
  place-items: center center;
  height: 100%;
}

.flavor-of-the-week-deals-content-menu {
  display: flex;
  row-gap: 2vh;
  place-items: center center;
  height: 100%;
}

.promotional-deal-item-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffee93; /* Background color */
  box-shadow: 0.5vh 1vh 0.5vh rgb(0 0 0 / 45%);
  border: 0.5px solid;
  color: black;
  text-decoration: none;
  font-family: inherit;
  font-size: 1.5rem;
  border-radius: 10px;
  width: 100%;
  font-weight: 550;
  padding: 2%;
}

.flavor-of-the-week-deal-item-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #a5d6a7; /* Background color */
  box-shadow: 0.5vh 1vh 0.5vh rgb(0 0 0 / 45%);
  border: 0.5px solid;
  color: black;
  text-decoration: none;
  font-family: inherit;
  font-size: 1.5rem;
  border-radius: 10px;
  width: 100%;
  font-weight: 550;
  padding: 2%;
  text-align: center;
}

.creamy-scoops-content-menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 12vh);
  gap: 1.5vh 2vh;
  place-items: center center;
  height: 100%;
}

.creamy-scoops-item-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffc3e1; /* Background color */
  box-shadow: 0.25vh 0.5vh 0.25vh rgb(0 0 0 / 45%);
  border: 0.5px solid;
  color: black;
  text-decoration: none;
  font-family: inherit;
  font-size: 1.5rem;
  border-radius: 10px;
  height: 11vh;
  width: 100%;
  font-weight: 550;
  padding: 0.5%;
}

.creamy-scoops-item-menu:nth-last-child(9) {
  align-self: center;
  grid-column: 1 / span 2;
}

.creamy-scoops-item-menu h2,
h6 {
  margin: 0;
}

.creamy-scoops-item-menu:active {
  background-color: #a5d6a7;
}

a {
  text-decoration: none;
  color: black;
}

a:visited {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: black;
}

a:focus {
  text-decoration: none;
  color: black;
}

a:hover,
a:active {
  text-decoration: none;
  color: black;
}

.flavors-form-options {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin: 2%;
  width: 100%;
}

.flavors-form-option {
  display: flex;
  flex-direction: column-reverse;
  margin: 5px;
  width: 30%;
  height: 75px;
  position: relative;
}

.flavors-label {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  inset: 0;
  padding: 5px;
  border: 2px solid #ccc;
  cursor: pointer;
  z-index: 90;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 45%);
  border-radius: 10px;
}

.flavors-form-option input {
  display: block;
  position: absolute;
  inset: 0;
  margin: 0;
  padding: 5px;
}

.flavors-form-option input[type="checkbox"] {
  opacity: 0.01;
  z-index: 100;
}

.flavors-form-option input[type="checkbox"]:checked + .flavors-label {
  border: 3px solid #ffc3e1;
}

.flavors-form-option input[type="checkbox"]:disabled + .flavors-label {
  background-color: #e0e0e0;
  opacity: 0.6;
}

.ingredients-information {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: x-small;
  margin-bottom: 3%;
  color: #5c3b1b;
  font-weight: 800;
}

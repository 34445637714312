body {
  margin: 0;
  padding: 0;
  font-family: "EB Garamond", serif;
  overflow: overflow;
  background-image: url("../../../public/images/ice-cream-clip-art-2.jpg");
  /* stylelint-disable */
  background-color: rgba(255 255 255, 0.9);
  background-color: rgba(255 255 255 / 90%);
  /* stylelint-enable */
  background-blend-mode: lighten;
  background-repeat: repeat;

  /* background-size: 20%; */
}

input,
textarea {
  border-radius: 10px;
  padding: 5px;
  font: inherit;
}

@media (width >= 1200px) {
  /* stylelint-disable */
  .condenseScreen {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }
  /* stylelint-enable */
}

@media (width <= 1200px) {
  body {
    background-size: 50%;
  }
}

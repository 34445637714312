.placed-orders-container {
  margin: 2%;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.5rem;
}

.placed-orders-container > div {
  /* border: 1px solid gray; */
  margin-bottom: 5%;
  background-color: #ffc3e1;
}

h2 {
  text-align: center;
}

.admin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc3e1;
  box-shadow: 0.5vh 1vh 0.5vh rgb(0 0 0 / 45%);
  border: 0.5px solid;
  color: black;
  text-decoration: none;
  font-family: inherit;
  font-size: 1rem;
  border-radius: 10px;
  width: 8lh;
  padding: 1%;
}

.admin-button:visited {
  text-decoration: none;
  color: black;
}

.admin-button:active {
  background-color: #a5d6a7;
}

.background {
  background-color: #fff7e3; /* Your base background color */
  background-image: radial-gradient(circle, #f9d8a7 10%, transparent 11%),
    radial-gradient(circle, #f9d8a7 10%, transparent 11%);
  background-size: 50px 50px;
  background-position:
    0 0,
    25px 25px; /* Offsets the dots to make a pattern */
}

.home-header-component {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.home-header-component h1 {
  position: relative;
  color: #5c3b1b;
  font-size: 3.5rem;
  font-family: Lobster, sans-serif;

  /* margin-top: 7.5vh; */

  /* margin-bottom: 2.5vh; */
}

.order-now-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc3e1;
  box-shadow: 0.5vh 1vh 0.5vh rgb(0 0 0 / 45%);
  border: 0.5px solid;
  color: black;
  text-decoration: none;
  font-family: inherit;
  font-size: 1rem;
  border-radius: 10px;
  height: 2lh;
  width: 9lh;
  margin-bottom: 2vh;
}

.order-now-button:visited {
  text-decoration: none;
  color: black;
}

.order-now-button:active {
  background-color: #a5d6a7;
}

.menu-selection-container {
  margin-left: 2vh;
  margin-right: 2vh;
}

.title {
  text-align: center;
}

.description {
  text-align: center;
  padding-bottom: 20px;
}

.menu-selection-children-container {
  margin-left: 1%;
  margin-right: 1%;
}

.order-now-component {
  padding: 3.5%;
}

.order-now-component h2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
}

.order-now-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 15vh);
  gap: 5px;
  padding: 2.5%;
  place-items: center center;
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffc3e1; /* Background color */
  box-shadow: 5px 7px 1px rgb(0 0 0 / 45%);
  border: 0.5px solid;
  color: black;
  text-decoration: none;
  font-family: inherit;
  font-size: 1.5rem;
  border-radius: 10px;
  height: 95%;
  width: 95%;
  font-weight: 550;
}

.grid-item h2,
h6 {
  margin: 0;
}

.grid-item:active {
  background-color: #a5d6a7;
}

a {
  text-decoration: none;
  color: black;
}

a:visited {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: black;
}

a:focus {
  text-decoration: none;
  color: black;
}

a:hover,
a:active {
  text-decoration: none;
  color: black;
}

.promotional-deals-component {
  padding: 3.5%;
}

.promotional-deals-component h2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
}

.promotional-deals-content {
  display: grid;
  grid-template-rows: repeat(auto-fit, 1fr);
  gap: 5px;
  padding: 2.5%;
  place-items: center center;
  padding-top: 0%;
}

.promotional-deal-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffee93; /* Background color */
  box-shadow: 5px 7px 1px rgb(0 0 0 / 45%);
  border: 0.5px solid;
  color: black;
  text-decoration: none;
  font-family: inherit;
  font-size: 1.25rem;
  border-radius: 10px;
  height: 95%;
  width: 95%;
  font-weight: 550;
  padding: 1%;
}

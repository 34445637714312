.collapsible-container-order {
  margin: 0 5px;
}

.collapsible-heading {
  display: flex;
  background-color: #8ed0d3;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
}

.collapsible-heading h3 {
  display: flex;
  margin: 0;
}

.collapsible-heading button {
  background-color: transparent;
  border: none;
}

.icon {
  transition: transform 0.3s ease;
}

.icon.closed {
  transform: rotate(-180deg);
}

.container-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5%;
  height: auto;
  opacity: 1;

  /* background-color: #FFF7E3; */
  transition:
    opacity 0.3s ease,
    height 0.3s ease;
}

.container-content.closed {
  opacity: 0;
  height: 0;
  overflow: hidden;

  /* margin-bottom: 2%; */
}

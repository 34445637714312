.modal {
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1%;
  padding-right: 1%;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  width: 500px;
  max-width: 100%;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  width: 100%;
}

.modal-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  text-align: center;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  text-align: center;
}

.modal-footer {
  display: flex;
  flex-direction: column;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1%;
}

.modal-title {
  margin: 0;
}

.modal-buttons button {
  padding: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font: inherit;
  width: 20%;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.modal-buttons button:disabled {
  padding: 5px;
  background-color: gray !important;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font: inherit;
  width: 20%;
}

label {
  display: flex;
  align-items: center;
}

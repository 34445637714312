.smoothies-menu-options-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin: 1.5%;
  width: 100%;
}

.smoothies-menu-option {
  margin: 5px;
  width: 30%;
  height: 125px;
  position: relative;
}

.smoothies-option-label {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  inset: 0;
  padding: 5px;
  border: 0.5px solid black;
  cursor: default;
  z-index: 90;
  box-shadow: 0.25vh 0.5vh 0.25vh rgb(0 0 0 / 45%);
  border-radius: 10px;
  background-color: #ffc3e1;
}
